import React from 'react'
import Locations from '../../components/Locations/locations'
import Header from '../../components/Header/header_en'
import Footer from '../../components/Footer/footer'
import LayoutEn from '../../components/layoutEn'
import Helmet from 'react-helmet'

const LocationsPage = () => (
    <LayoutEn>
        <Helmet
          title="VIP CARS - Rent a Car | Where to rent luxury cars in Sarajevo?"
          meta={[
            { name: 'description', content: 'VIP Cars - Rent a Car | Take over your favorite luxury car (vehicle) wherever you want in Bosnia, Serbia & Croatia | T: +387 62 520 556.' },
          ]} >
        </Helmet>
        <div id="locations">
    <div className="header-back"></div>
    <Header/>
   
    <section className="section locations">
         <div className="container">

            <Locations/>
        </div>
   </section>
   <Footer/>
   </div>
   </LayoutEn>
)

export default LocationsPage
